<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">

            <!-- Brand logo-->
            <b-link class="brand-logo">
                <vuexy-logo/>
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col
                lg="8"
                class="d-none d-lg-flex align-items-center p-5"
            >
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5"/>
            </b-col>
            <!-- /Left Text-->

            <!-- Login-->
            <b-col
                lg="4"
                class="d-flex align-items-center auth-bg px-2 p-lg-5"
            >
                <b-col
                    sm="8"
                    md="6"
                    lg="12"
                    class="px-xl-2 mx-auto"
                >
                    <b-card-title
                        title-tag="h2"
                        class="font-weight-bold mb-1"
                    >
                        Welcome to Cineself! 👋
                    </b-card-title>
                    <b-card-text class="mb-2">
                        Please sign-in to your account and start the adventure
                    </b-card-text>

                    <!-- form -->
                    <validation-observer ref="loginValidation">
                        <b-form
                            class="auth-login-form mt-2"
                            @submit.prevent
                        >
                            <!-- email -->
                            <b-form-group
                                label="Email"
                                label-for="login-email"
                            >
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="Email"
                                    rules="required|email"
                                >
                                    <b-form-input
                                        id="login-email"
                                        v-model="form.username"
                                        :state="errors.length > 0 ? false:null"
                                        name="login-email"
                                        placeholder="john@example.com"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- forgot password -->
                            <b-form-group>
                                <div class="d-flex justify-content-between">
                                    <label for="login-password">Password</label>
                                    <b-link @click="goToPasswordRecover()">
                                        <small>Forgot Password?</small>
                                    </b-link>
                                </div>
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="Password"
                                    rules="required"
                                >
                                    <b-input-group
                                        class="input-group-merge"
                                        :class="errors.length > 0 ? 'is-invalid':null"
                                    >
                                        <b-form-input
                                            id="login-password"
                                            v-model="form.password"
                                            :state="errors.length > 0 ? false:null"
                                            class="form-control-merge"
                                            :type="passwordFieldType"
                                            name="login-password"
                                            placeholder=""
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                class="cursor-pointer"
                                                :icon="passwordToggleIcon"
                                                @click="togglePasswordVisibility"
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- checkbox -->
                            <b-form-group>
                                <b-form-checkbox
                                    id="remember-me"
                                    v-model="status"
                                    name="checkbox-1"
                                >
                                    Remember Me
                                </b-form-checkbox>
                            </b-form-group>

                            <!-- submit buttons -->
                            <b-button
                                type="submit"
                                variant="primary"
                                block
                                @click="validationForm"
                            >
                                Sign in
                            </b-button>
                        </b-form>
                    </validation-observer>

                    <b-card-text class="text-center mt-2">
                        <span>Have an activation code? </span>
                        <b-link @click="goToRegister()">
                            <span>Activate account</span>
                        </b-link>
                    </b-card-text>

                    <!-- divider
                    <div class="divider my-2">
                      <div class="divider-text">
                        or
                      </div>
                    </div>
                    -->
                    <!-- social buttons
                    <div class="auth-footer-btn d-flex justify-content-center">
                      <b-button
                        variant="facebook"
                        href="javascript:void(0)"
                      >
                        <feather-icon icon="FacebookIcon" />
                      </b-button>
                      <b-button
                        variant="twitter"
                        href="javascript:void(0)"
                      >
                        <feather-icon icon="TwitterIcon" />
                      </b-button>
                      <b-button
                        variant="google"
                        href="javascript:void(0)"
                      >
                        <feather-icon icon="MailIcon" />
                      </b-button>
                      <b-button
                        variant="github"
                        href="javascript:void(0)"
                      >
                        <feather-icon icon="GithubIcon" />
                      </b-button>
                    </div>
                    -->
                </b-col>
            </b-col>
            <!-- /Login-->
        </b-row>
        <div id="app-ver">
            <span>ver {{curVer}}</span>
        </div>
    </div>
</template>

<script>
/* eslint-disable global-require */
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
} from 'bootstrap-vue';
import {required, email} from '@validations';
import {togglePasswordVisibility} from '@core/mixins/ui/forms';
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Parse from 'parse';

export default {
    components: {
        BRow,
        BCol,
        BLink,
        BFormGroup,
        BFormInput,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BCardText,
        BCardTitle,
        BForm,
        BButton,
        VuexyLogo,
        ValidationProvider,
        ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            form: {
                username: "",
                password: ""
            },
            loading: false,
            status: '',
            password: '',
            userEmail: '',
            sideImg: require('@/assets/images/pages/login-v2.svg'),
            // validation rulesimport store from '@/store/index'
            required,
            email,
            curVer: `${process.env.VUE_APP_VERSION}`,
        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
                return this.sideImg
            }
            return this.sideImg
        },
    },
    methods: {
        goToRegister() {
            this.$router.push({name: 'register'});
        },
        goToPasswordRecover() {
            this.$router.push({name: 'passwordRecover'});
        },
        login() {
            if (!this.loading) {
                this.loading = true;
                let self = this;
                Parse.User.logIn(this.form.username, this.form.password).then((userdata) => {
                    //console.log("User data: ", userdata);
                    if (userdata) {
                        if(userdata.attributes.userType === 'producer'){
                            self.$router.push({name: 'home'});
                            self.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Welcome back, " + userdata.attributes.firstName + "!",
                                    icon: 'EditIcon',
                                    variant: 'success',
                                },
                            });
                        }else {
                            self.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "You are trying to login with a talent profile! This area is restricted only for producers and talent agents!",
                                    icon: 'TimesIcon',
                                    variant: 'danger',
                                },
                            });
                            Parse.User.logOut();
                        }
                        //self.$root.openToast("Welcome back, "+userdata.attributes.firstName+"!", "secondary");

                        this.loading = false;
                    }
                }).catch(() => {
                    self.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Invalid username and/or password!",
                            icon: 'XIcon',
                            variant: 'danger',
                        },
                    })
                    this.loading = false;
                    //self.$root.openToast("Incorrect username or password!", "danger");
                });
            }
        },
        validationForm() {
            this.$refs.loginValidation.reset();
            this.$refs.loginValidation.validate().then(success => {
                if (success) {
                    this.login();
                } else {
                    console.log("Unsuccessful form validation");
                }
            }).catch(err => {
                console.log("Unsuccessful form validation", err.message);
            })
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
